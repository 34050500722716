/* Teaser component (print)
***************************/

.teaser {
    @extend %resetColors;

    &:hover,
    &:focus-within {
        box-shadow: none;
    }
}

.teaser-image {
    min-height: 0;

    .flag-preview {
        position: static;
        max-width: none;
        margin: 0;
        transform: none;
    }
}

.teaser.teaser .teaser-image {
    border-bottom: 0;
}

.teaser-text {
    padding: 1em;
}

.teaser-link::after {
    @extend %exposeLinkHref;
}
