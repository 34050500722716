/* Hidden elements & components in print styles
***********************************************/

.back-top,
.card-hero .card-image,
.card-profile .card-image,
#cookieBanner,
.hero-cta,
.infogram iframe,
.link svg,
.navigation-container,
.skip-to-main,
.tabs [role="tablist"],
.teaser-image img,
.teaser-cta,
.toc-buttons-container,
.toc-container,
.video-container,
.footer-layout .list-container {
    display: none;
}
