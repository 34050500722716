/* Panel component (print)
**************************/

.panel-container + .panel-container {
    margin-top: 0;
}

.panel,
.panel.panel--thin {
    padding: 1.5em;
}

.panel > .panel-caveat {
    margin: 0;
}
