/* Article component (print)
****************************/

.tmpl-report {
    .article.landmark {
        > .container.report-content {
            .section:first-child {
                margin-top: 0;
            }
        }
    }
}
