/* Columns component (print)
****************************/

.columns {
    display: block;

    > *,
    > .teaser {
        margin: 1em 0;
    }
}

.columns.columns--dividers {
    > .column-item {
        margin: 0;
        padding: 1em 0;
        border: 0;
    }
}
