/* Buttons component (print)
****************************/

.btn {
    padding: 0;
    border: 0;

    &:hover,
    &:focus {
        @extend %resetColors;
    }
}

a.btn::after {
    @extend %exposeLinkHref;
}
