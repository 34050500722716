/* Global print styles
**********************/

body,
html {
    color: #333;
}

.link {
    display: block;
}

.vertical-flow {
    margin-top: 1em;
    margin-bottom: 1em;
}

.scheme--light-gray,
.scheme--primary,
.scheme--secondary,
.scheme--white {
    @extend %resetColors;
}
