/* Footer component (print)
***************************/

.footer {
    @extend %resetColors;
    padding-top: 1em;
    padding-bottom: 1em;
    border: 0;
}

.footer-logos {
    .logo-symbol,
    .logo-text {
        fill: currentColor;
    }
}

.footer-disclaimer {
    margin-top: 0;
    color: inherit;
}
