/* Card component (print)
*************************/

.card {
    @extend %resetColors;

    .card-inset,
    .card-text {
        display: block;
    }

    .card-inset {
        padding: 1em;
    }

    .icon {
        stroke: #333 !important;
    }
}

.card-profile {
    .card-text {
        max-width: none;
    }

    .link::after {
        @extend %exposeLinkHref;
    }
}

.card-hero {
    .card-inset {
        @extend %resetColors;
        min-height: 0;
    }

    .card-text--outline {
        text-shadow: none;
    }

    .card-inset.scheme--primary,
    .card-inset.scheme--secondary {
        .card-image + .card-text {
            @extend %resetColors;
        }
    }
}
