/* SCSS placeholder templates
 * (Print styling)
*****************************/

// Expose link href content after the link itself
%exposeLinkHref {
    position: static;
    display: block;
    font-size: initial;
    content: "(" attr(href) ")";
}

%resetColors {
    color: unset;
    background-color: unset;
}

%resetFontSize {
    font-size: initial;
    font-weight: initial;
    line-height: initial;
}

%resetVisuallyHidden {
    position: static;
    width: auto;
    height: auto;
    clip: unset;
    white-space: normal;
    overflow: auto;
}
