/* Hero component (print)
*************************/

.hero {
    @extend %resetColors;
}

.hero-text {
    @extend %resetColors;
    min-height: 0;
    padding: 0 1.5em;
}
