/* Tabs component (print)
*************************/

.tab-panel {
    [data-tab-trigger].visually-hidden {
        @extend %resetVisuallyHidden;
    }
}

.tab-panel[hidden] {
    display: block !important;
}
