/* SCSS MANIFEST (Print)
************************/
/* SCSS placeholder templates
 * (Print styling)
*****************************/
.teaser-link::after, .card-profile .link::after, a.btn::after {
  position: static;
  display: block;
  font-size: initial;
  content: "(" attr(href) ")";
}

.footer, .teaser, .card-hero .card-inset.scheme--primary .card-image + .card-text,
.card-hero .card-inset.scheme--secondary .card-image + .card-text, .card-hero .card-inset, .card, .hero-text, .hero, .btn:hover, .btn:focus, .scheme--light-gray,
.scheme--primary,
.scheme--secondary,
.scheme--white {
  color: unset;
  background-color: unset;
}

.tab-panel [data-tab-trigger].visually-hidden {
  position: static;
  width: auto;
  height: auto;
  clip: unset;
  white-space: normal;
  overflow: auto;
}

/* Global print styles
**********************/
body,
html {
  color: #333;
}

.link {
  display: block;
}

.vertical-flow {
  margin-top: 1em;
  margin-bottom: 1em;
}

/* Hidden elements & components in print styles
***********************************************/
.back-top,
.card-hero .card-image,
.card-profile .card-image,
#cookieBanner,
.hero-cta,
.infogram iframe,
.link svg,
.navigation-container,
.skip-to-main,
.tabs [role=tablist],
.teaser-image img,
.teaser-cta,
.toc-buttons-container,
.toc-container,
.video-container,
.footer-layout .list-container {
  display: none;
}

/* Buttons component (print)
****************************/
.btn {
  padding: 0;
  border: 0;
}
/* Page component (print)
*************************/
body:not(.tmpl-blank) .sticky-footer {
  display: block;
}

/* Header component (print)
***************************/
.header-logo {
  max-width: none;
}

/* Article component (print)
****************************/
.tmpl-report .article.landmark > .container.report-content .section:first-child {
  margin-top: 0;
}

/* Hero component (print)
*************************/
.hero-text {
  min-height: 0;
  padding: 0 1.5em;
}

/* Columns component (print)
****************************/
.columns {
  display: block;
}
.columns > *,
.columns > .teaser {
  margin: 1em 0;
}

.columns.columns--dividers > .column-item {
  margin: 0;
  padding: 1em 0;
  border: 0;
}

/* Card component (print)
*************************/
.card .card-inset,
.card .card-text {
  display: block;
}
.card .card-inset {
  padding: 1em;
}
.card .icon {
  stroke: #333 !important;
}

.card-profile .card-text {
  max-width: none;
}
.card-hero .card-inset {
  min-height: 0;
}
.card-hero .card-text--outline {
  text-shadow: none;
}
/* Panel component (print)
**************************/
.panel-container + .panel-container {
  margin-top: 0;
}

.panel,
.panel.panel--thin {
  padding: 1.5em;
}

.panel > .panel-caveat {
  margin: 0;
}

/* Tabs component (print)
*************************/
.tab-panel[hidden] {
  display: block !important;
}

/* Teaser component (print)
***************************/
.teaser:hover, .teaser:focus-within {
  box-shadow: none;
}

.teaser-image {
  min-height: 0;
}
.teaser-image .flag-preview {
  position: static;
  max-width: none;
  margin: 0;
  transform: none;
}

.teaser.teaser .teaser-image {
  border-bottom: 0;
}

.teaser-text {
  padding: 1em;
}

/* Footer component (print)
***************************/
.footer {
  padding-top: 1em;
  padding-bottom: 1em;
  border: 0;
}

.footer-logos .logo-symbol,
.footer-logos .logo-text {
  fill: currentColor;
}

.footer-disclaimer {
  margin-top: 0;
  color: inherit;
}